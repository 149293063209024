/*
 |-----------------------------------------------------------------------------
 | components/atoms/Select/Select.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';

import Error from '@/atoms/Error';

import * as ISelect from './types';

const Select: FC<ISelect.IProps> = ({
	id,
	isDisabled,
	isInvalid,
	isOptional,
	label,
	name,
	onBlur,
	onChange,
	options,
	placeholder,
	utilities,
	variant,
}) => {
	// ray('Debug atom Select:', {
	// 	id: id,
	// 	isDisabled: isDisabled,
	// 	isInvalid: isInvalid,
	// 	isOptional: isOptional,
	// 	label: label,
	// 	modifiers: modifiers,
	// 	name: name,
	// 	options: options,
	// 	placeholder: placeholder,
	// 	utilities: utilities,
	// 	variant: variant,
	// }).red();

	let classes;
	switch (variant) {
		case 'simple':
			classes = `
				border-gray-300
				rounded-md
				shadow-sm
				focus:border-indigo-300
				focus:ring
				focus:ring-indigo-200
				focus:ring-opacity-50
			`;
			break;
		case 'solid':
			classes = `
				bg-gray-100
				border-transparent
				rounded-md
				focus:bg-white
				focus:border-gray-500
				focus:ring-0
			`;
			break;
		case 'underline':
			classes = `
				bg-transparent
				border-0
				border-b
				border-brand-1
				mt-0
				px-0.5
				focus:border-brand-4
				focus:ring-0
			`;
			break;
		default:
			classes = '';
	}

	return (
		<label
			className={`
				block
				cursor-pointer
				text-xl
				text-brand-1
				sm:text-lg
				${utilities ? utilities : ''}
			`}
			data-testid="select"
			htmlFor={id}
		>
			<span>{label}</span>
			<select
				className={`
					block
					mt-1
					w-full
					disabled:opacity-25
					${classes}
				`}
				disabled={isDisabled}
				id={id}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				required={!isOptional}
			>
				<option disabled={!isOptional} value="">
					{placeholder}
				</option>
				{map(options, (option, index) => (
					<option
						key={`${index}${get(option, 'value')}`}
						value={get(option, 'value')}
					>
						{get(option, 'label')}
					</option>
				))}
			</select>
			{isInvalid && (
				<Error text={`${name} is required`} utilities={`mt-1`} />
			)}
		</label>
	);
};

export default Select;
